import { v4 as uuidv4 } from "uuid";

import "./middleware.js";

const uid = uuidv4();
const namespace = uuidv4();
let pageLoadId = uuidv4();

const ONE_YEAR = 60 * 60 * 24 * 365 * 1;

function productsTransformer(data) {
  data = JSON.parse(data);
  console.info({ ssProductAutocompleteData: data });

  const results = (data?.results ?? []).map(result => {
    const variants = JSON.parse(result.variants.replace(/&quot;/g, '"'));
    const title = result.title.replace(/&amp;/g, "&");

    return { ...result, title, selectedVariant: variants[0] };
  });

  return { ...data, results };
}

function recommendationTransformer(data) {
  data = JSON.parse(data);
  console.info({ ssRecommendData: data });

  const results = (data?.[0]?.results ?? []).map(
    ({ mappings, attributes }) => ({
      ...attributes,
      id: mappings.core.uid,
      recId: mappings.core.uid,
      options: JSON.parse(attributes.options ?? null) ?? [],
      metafields: JSON.parse(attributes.metafields),
      color_swatches: JSON.parse(attributes.color_swatches),
      variants: JSON.parse(attributes.variants).map(variant => ({
        ...variant,
        compareAtPrice: variant.compare_at_price,
        productId: variant.product_id,
        selectedOptions: variant.selected_options,
      })),
    })
  );

  return { results };
}

export default ({ app, isDev, $config }, inject) => {
  const searchspring = {
    setPageLoadId() {
      pageLoadId = uuidv4();
    },
    getIsuid() {
      return app.$cookies.get("_isuid");
    },
    setIsuid() {
      app.$cookies.set("_isuid", uid, this.getCookieOptions());
    },
    getuserId() {
      return app.$cookies.get("ssUserId");
    },
    setUserId() {
      app.$cookies.set("ssUserId", uid, this.getCookieOptions());
    },
    getSessionIdNamespace() {
      return app.$cookies.get("ssSessionIdNamespace");
    },
    setSessionIdNamespace() {
      app.$cookies.set(
        "ssSessionIdNamespace",
        namespace,
        this.getCookieOptions({ maxAge: null })
      );
    },
    getViewedProducts() {
      return app.$cookies.get("ssViewedProducts");
    },
    setViewedProducts(sku) {
      const viewedProducts = this.getViewedProducts();
      let products = viewedProducts?.includes(sku)
        ? viewedProducts
        : [sku, viewedProducts].filter(Boolean).join(",");

      app.$cookies.set(
        "ssViewedProducts",
        products,
        this.getCookieOptions({ maxAge: ONE_YEAR * 7 })
      );
    },
    getCookieOptions({ maxAge = ONE_YEAR, path = "/" } = {}) {
      return {
        ...(maxAge && { maxAge }),
        ...{ path },
        ...(isDev
          ? {}
          : { sameSite: "lax", domain: $config.domainName, secure: true }),
      };
    },
    getProfileContext() {
      return {
        pageLoadId,
        sessionId: this.getSessionIdNamespace(),
        userId: this.getuserId(),
        website: { trackingCode: "g28s7j" },
      };
    },
    callBeaconApi(payload = []) {
      const body = payload.length === 1 ? payload[0] : payload;
      return app.$axios.post("https://beacon.searchspring.io/beacon", body);
    },
    getPersonalizationParams() {
      const lineSkus = app.store.getters["bag/lineSkus"];
      const customerEmail = app.store.state.wishlist.customer?.email;

      return {
        cart: lineSkus.length === 0 ? "" : lineSkus.join(","),
        lastViewed: this.getViewedProducts(),
        ...(customerEmail && { shopper: customerEmail }),
      };
    },
    getUserTrackingParams() {
      return {
        sessionId: this.getSessionIdNamespace(),
        userId: this.getuserId(),
        pageLoadId,
        domain: "https://www.snooze.com.au",
      };
    },
    recommend({ tag, limit, sku }) {
      console.assert(tag, "tag is required");

      let searchParams = new URLSearchParams({
        tags: tag,
        limits: limit ?? 12,
        ...(sku && { products: sku }),
        ...this.getPersonalizationParams(),
      });

      const siteId = "g28s7j";
      const url = `https://${siteId}.a.searchspring.io/boost/${siteId}/recommend?${searchParams}`;

      console.info({ ssRecommendation: url });

      return app.$axios.get(url, {
        transformResponse: [recommendationTransformer],
      });
    },
    blogSearch({ query, page = 1, pageSize, tags = [] } = {}) {
      const siteId = "wf2lv2";
      const bgfilters = tags.reduce(
        (acc, tag) => ({ ...acc, "bgfilter.tags": tag }),
        {}
      );

      let searchParams = new URLSearchParams({
        ...(query && { q: query }),
        page,
        resultsPerPage: pageSize,
        siteId,
        resultsFormat: "json",
        ...bgfilters,
        ...this.getUserTrackingParams(),
        ...this.getPersonalizationParams(),
      });

      const url = `https://${siteId}.a.searchspring.io/api/search/search.json?${searchParams}`;
      console.info({ ssBlogSearch: url });
      return app.$axios.get(url);
    },
    suggest({ siteId, query }) {
      let searchParams = new URLSearchParams({
        siteId,
        suggestionCount: 5,
        query,
        integratedSpellCorrection: true,
      });

      const url = `https://${siteId}.a.searchspring.io/api/suggest/query?${searchParams}
      `;
      console.info({ ssSuggest: url });
      return app.$axios.get(url);
    },
    async autocomplete({
      siteId,
      query,
      resultsPerPage = 3,
      responseTransformers = [data => JSON.parse(data)],
    }) {
      let searchParams = new URLSearchParams({
        siteId,
        resultsPerPage,
        q: query,
        ...this.getUserTrackingParams(),
        ...this.getPersonalizationParams(),
        resultsFormat: "json",
      });
      const url = `https://${siteId}.a.searchspring.io/api/search/autocomplete.json?${searchParams}`;
      console.info({ ssAutoComplete: url });

      const response = await app.$axios.get(url, {
        transformResponse: responseTransformers,
      });

      return response;
    },
    async collectionsAutocomplete(query) {
      const siteId = "bkqbz7";
      const suggenstions = await this.suggest({ siteId, query });
      const suggestion = suggenstions.data?.suggested?.text ?? query;
      return this.autocomplete({
        siteId,
        query: suggestion,
        resultsPerPage: 5,
      });
    },
    async productsAutocomplete(query) {
      const siteId = "g28s7j";
      const suggestionResponse = await this.suggest({ siteId, query });

      const suggestions = [
        ...new Set([
          suggestionResponse.data?.suggested?.text,
          ...suggestionResponse.data?.alternatives.map(alt => alt.text),
        ]),
      ];

      const { data } = await this.autocomplete({
        siteId,
        query: suggestions?.[0] ?? query,
        responseTransformers: [productsTransformer],
      });

      return { data, suggestions };
    },
    async blogsAutocomplete(query) {
      const siteId = "wf2lv2";
      const suggenstions = await this.suggest({ siteId, query });
      const suggestion = suggenstions.data?.suggested?.text ?? query;
      return this.autocomplete({ siteId, query: suggestion });
    },
  };

  inject("search", searchspring);
};
