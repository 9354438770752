let isScriptLoaded = false;
const siteId = "g28s7j";

function addScript() {
  return new Promise((resolve, reject) => {
    if (isScriptLoaded) {
      resolve(); // Return if script is already loaded
      return;
    }

    const scriptElement = document.createElement("script");
    scriptElement.src = "https://cdn.searchspring.net/intellisuggest/is.min.js";
    scriptElement.type = "text/javascript";
    scriptElement.setAttribute("hid", "search-spring-script");

    scriptElement.onload = () => {
      console.log("SEARCH SPRING TRACKING ONLOAD CALLED");
      isScriptLoaded = true;
      resolve(); // Resolve when script is loaded
    };

    scriptElement.onerror = () => {
      console.error("SEARCH SPRING SCRIPT failed to load");
      reject(new Error("Script failed to load"));
    };

    // Inject script into the head
    document.head.appendChild(scriptElement);
  });
}

export default function ({ app }, inject) {
  inject("searchspring", {
    async trackProductClick(el, intellisuggestData, intellisuggestSignature) {
      try {
        await addScript();
        if (!window?.IntelliSuggest) {
          console.error("IntelliSuggest is not loaded");
          return;
        }

        window.IntelliSuggest.clickItem(el, {
          siteId,
          intellisuggestData,
          intellisuggestSignature,
        });
      } catch (err) {
        console.error("Error in product view tracking:", err);
        app.$bugsnag.notify(err);
      }
    },

    async trackProductView(productCode, sku) {
      try {
        await addScript();
        if (!window?.IntelliSuggest) {
          console.error("IntelliSuggest is not loaded");
          return;
        }
        const customerEmail = app.store.state.wishlist.customer?.email;
        window.IntelliSuggest.init({
          siteId,
          context: "Product/" + productCode,
          seed: [productCode],
        });
        window.IntelliSuggest.setShopperId(customerEmail || "");
        window.IntelliSuggest.viewItem({
          uid: productCode,
          sku,
        });
      } catch (err) {
        console.error("Error in product view tracking:", err);
        app.$bugsnag.notify(err);
      }
    },

    async trackBasketView() {
      try {
        await addScript();
        if (!window?.IntelliSuggest) {
          console.error("IntelliSuggest is not loaded");
          return;
        }
        const customerEmail = app.store.state.wishlist.customer?.email;
        const seed = app.store.getters["bag/lineSkus"] || [];
        window.IntelliSuggest.init({
          siteId,
          context: "Basket",
          seed,
        });
        const lines = app.store.getters["bag/lines"] || [];
        lines.forEach(l => {
          const { price, id, sku } = l.variant;
          console.log(l.quantity, price, id, sku);
          window.IntelliSuggest.haveItem({
            uid: id,
            sku,
            qty: l.quantity,
            price,
          });
        });
        window.IntelliSuggest.setCurrency({ code: "AUD" });
        window.IntelliSuggest.inBasket({});
        console.log(app.store.getters["bag/lines"], customerEmail);
      } catch (err) {
        console.error("Error in basket view tracking:", err);
        app.$bugsnag.notify(err);
      }
    },
  });
}
